<template>
  <div class="apply-container">
    <div class="apply-title">{{ $t('line.title') }}</div>
    <div class="form-box">
      <el-form
          v-loading="loading"
          size="mini"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          :element-loading-text="$t('line.loadingText')"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          label-width="80px"
          style="width: 35%"
          class="ruleForm">
        <el-form-item :label="$t('line.ClientCode')" prop="ClientCode">
          <el-input v-model="ruleForm.ClientCode"></el-input>
        </el-form-item>
        <el-form-item :label="$t('line.GoodsName')" prop="GoodsName">
          <el-input v-model="ruleForm.GoodsName"></el-input>
        </el-form-item>
<!--        <el-form-item :label="$t('line.DestinationId')" prop="DestinationId">-->
<!--          <el-select style="width: 100%" v-model="ruleForm.DestinationId" :placeholder="$t('line.placeholder')">-->
<!--            <el-option-->
<!--                v-for="item in destinationOptions"-->
<!--                :key="item.Id"-->
<!--                :label="item.Name"-->
<!--                :value="item.Id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item :label="$t('line.GoodsTypeId')" prop="GoodsTypeId">
          <el-select style="width: 100%" v-model="ruleForm.GoodsTypeId" :placeholder="$t('line.placeholder')">
            <el-option
                v-for="item in goodTypes"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('line.CarrierId')" prop="CarrierId">
          <el-select style="width: 100%" v-model="ruleForm.CarrierId" :placeholder="$t('line.placeholder')">
            <el-option
                v-for="item in carrierOptions"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('line.TimerId')" prop="TimerId">
          <el-select style="width: 100%" v-model="ruleForm.TimerId" :placeholder="$t('line.placeholder')">
            <el-option
                v-for="item in timerOptions"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('line.WareHouseId')" prop="WareHouseId">
          <el-select style="width: 100%" v-model="ruleForm.WareHouseId" :placeholder="$t('line.placeholder')">
            <el-option
                v-for="item in wareHouseOptions"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item :label="$t('line.RecipientName')" prop="RecipientName">-->
<!--          <el-input v-model="ruleForm.RecipientName"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item :label="$t('line.RecipientPhone')" prop="RecipientPhone">-->
<!--          <el-input v-model="ruleForm.RecipientPhone"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item :label="$t('line.RecipientEmail')" prop="RecipientEmail">-->
<!--          <el-input v-model="ruleForm.RecipientEmail"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item :label="$t('line.RecipientPostCode')" prop="RecipientPostCode">-->
<!--          <el-input v-model="ruleForm.RecipientPostCode"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item :label="$t('line.RecipientAddress')" prop="RecipientAddress">-->
<!--          <el-input v-model="ruleForm.RecipientAddress"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item :label="$t('line.Rem')" prop="Rem">
          <el-input type="textarea" v-model="ruleForm.Rem"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('line.submitForm') }}</el-button>
          <el-button @click="resetForm('ruleForm')">{{ $t('line.resetForm') }}</el-button>
        </el-form-item>
      </el-form>
      <address-select style="width: 60%" ref="addr" @refresh="init" @select-address="selectAddress" :options="AddrOptions"></address-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {CountryInfo, getGoodsType} from "@/api/system"
import {dedicatedAddOrder} from "@/api/member";
import AddressSelect from "@/components/AddressSelect.vue";
export default {
  components: {AddressSelect},
  data() {
    return {
      ruleForm: {
        ClientCode: "",
        // DestinationId: "",
        GoodsName: "",
        // RecipientAddress: "",
        // RecipientPostCode: "",
        // RecipientEmail: "",
        // RecipientPhone: "",
        // RecipientName: "",
        GoodsTypeId: "",
        CarrierId: "",
        TimerId: "",
        WareHouseId: "",
        Rem: ""
      },
      AddrOptions: [],
      rules: {
        // DestinationId: [
        //   { required: true, message: this.$t('line.placeholderDestinationId'), trigger: 'change' }
        // ],
        GoodsTypeId: [
          { required: true, message: this.$t('line.placeholderGoodsTypeId'), trigger: 'change' }
        ],
        CarrierId: [
          { required: true, message: this.$t('line.placeholderCarrierId'), trigger: 'change' }
        ],
        TimerId: [
          { required: true, message: this.$t('line.placeholderTimerId'), trigger: 'change' }
        ],
        WareHouseId: [
          { required: true, message: this.$t('line.placeholderWareHouseId'), trigger: 'change' }
        ],
        ClientCode: [
          { required: true, message: this.$t('line.placeholderClientCode'), trigger: 'blur' }
        ],
        GoodsName: [
          { required: true, message: this.$t('line.placeholderGoodsName'), trigger: 'blur' }
        ],
        // RecipientAddress: [
        //   { required: true, message: this.$t('line.placeholderRecipientAddress'), trigger: 'blur' }
        // ],
        // RecipientPhone: [
        //   { required: true, message: this.$t('line.placeholderRecipientPhone'), trigger: 'blur' }
        // ],
        // RecipientName: [
        //   { required: true, message: this.$t('line.placeholderRecipientName'), trigger: 'blur' }
        // ]
      },
      wareHouseOptions: [],
      carrierOptions: [],
      timerOptions: [],
      destinationOptions: [],
      loading: false,

    }
  },
  computed: {
    ...mapGetters(['goodTypes', 'TenantId']),
  },
  methods: {
    getGoodsType() {
      const data = {
        pageIndex: 1,
        pageRows: 10
      }
      getGoodsType(data).then(response => {
        const { Data } = response;
        this.$store.dispatch('consolidation/changeGoodTypes', Data)
        this.ruleForm.GoodsTypeId = Data[0]?.Id
      })
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(response => {
        this.wareHouseOptions = response
        this.ruleForm.WareHouseId = response[0]?.Id
      })
    },
    getCarrier() {
      this.$store.dispatch('webSite/getCarrier').then(response => {
        this.carrierOptions = response
        this.ruleForm.CarrierId = response[0]?.Id
      })
    },
    getTimer() {
      this.$store.dispatch('webSite/getTimer').then(response => {
        this.timerOptions = response
        this.ruleForm.TimerId = response[0]?.Id
      })
    },
    init() {
      return new Promise((rs) => {
        this.$store.dispatch('webSite/getAddress').then(data => {
          this.AddrOptions = data;
          this.AddrOptions.sort((a, b) => {
            if (a.IsDefaultAddress && !b.IsDefaultAddress) return -1;
            if (!a.IsDefaultAddress && b.IsDefaultAddress) return 1;
            return 0;
          });
          const defaults = this.AddrOptions.filter(item => { return item.IsDefaultAddress })
          if (defaults.length) {
            this.$refs.addr.onNav(defaults[0])
          } else {
            this.$refs.addr.onNav(this.AddrOptions[0])
          }
          rs(true)
        })
      })
    },
    selectAddress(itemId) {
      this.$set(this.ruleForm, 'ClientAddressId', itemId)
    },
    getDestination() {
      const data = {
        tenantId: this.TenantId
      }
      CountryInfo(data).then(response => {
        const { Data } = response
        this.destinationOptions = Data
        this.ruleForm.DestinationId = Data[0]?.Id
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            CustomerNumber: this.ruleForm.ClientCode,
            GoodsName: this.ruleForm.GoodsName,
            ClientAddressId: this.ruleForm.ClientAddressId,
            WareHouseId: this.ruleForm.WareHouseId,
            GoodsTypeId: this.ruleForm.GoodsTypeId,
            CarrierId: this.ruleForm.CarrierId,
            TimerId: this.ruleForm.TimerId,
            Rem: this.ruleForm.Rem,
          }
          this.loading = true
          dedicatedAddOrder(data).then(response => {
            this.loading = false
            const { Code, Msg } = response
            if (Code === 200) {
              this.$message.success(this.$t('line.submitSuccess'))
              this.resetForm("ruleForm")
            } else {
              this.$message.warning(Msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$set(this, formName, {
        ClientCode: "",
        GoodsName: "",
        Rem: "",
        GoodsTypeId: this.goodTypes[0]?.Id,
        CarrierId: this.carrierOptions[0]?.Id,
        TimerId: this.timerOptions[0]?.Id,
        WareHouseId: this.wareHouseOptions[0]?.Id
      })
      this.$refs[formName].resetFields();
    }
  },
  created() {
    this.init()
    this.getGoodsType()
    this.getCarrier()
    this.getWareHouse()
    this.getTimer()
  }
}

</script>

<style scoped lang="scss">
::v-deep .address-box .addr-box {
  height: 200px;
}
.apply-container {
  padding: 4rem 2rem;
  .apply-title {
    padding: 1.5rem 2.5rem;
    color: #225493;
    background-color: #F0F4FB;
    font-size: 1.6rem;
  }
  .form-box {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

}
</style>
